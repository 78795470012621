import { toast } from 'react-toastify'

export function notify(message, type) {
  toast(message, {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    type,
  })
}
