import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate } from 'react-router'
import './AdminDetail.css'
import { useEffect, useState } from 'react'
import Methods from '../../../utils/Methods'
import { notify } from '../../../utils/notify'
import { ToggleSwitch } from '../../../components/ToggleSwitch'
import Spinner from '../../../components/Spinner'

export default function AdminDetail() {
  const location = useLocation()
  const { user, action } = location.state
  const [showPassword, setShowPassword] = useState([false, false])
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    password: '',
    status: 'active',
  })
  const [checkBoxValue, setCheckBoxValue] = useState(
    user?.status !== 'inactive'
  )
  const [isLoading, setIsLoading] = useState(false)
  const __emailValidator =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
  const navigate = useNavigate()

  useEffect(() => {
    setNewUser({ ...newUser, ...user })
  }, [])

  const saveUser = async () => {
    setIsLoading(true)
    if (Object.values(newUser).some(x => x === '')) {
      setIsLoading(false)
      notify('Favor de llenar todos los campos', 'warning')
      return
    }
    if (!(await validateData())) {
      setIsLoading(false)
      return
    }

    action === 'create' ? registerAdmin() : updateAdmin()
  }

  const registerAdmin = async () => {
    await Methods.addUser({ ...newUser, userType: 'admin' })
      .then(() => {
        notify('Usuario agregado correctamente', 'success')
        navigate('/admins')
      })
      .catch(error => {
        console.log(error)
        notify(error.response.data, 'error')
      })
  }

  const updateAdmin = async () => {
    await Methods.updateUser({
      user: {
        ...newUser,
        userType: 'admin',
      },
      isPasswordEdited: newPassword,
    })
      .then(() => {
        setIsLoading(false)
        notify('Datos actualizados correctamente', 'success')
        navigate('/admins')
      })
      .catch(error => {
        setIsLoading(false)
        console.log(error)
      })
  }

  const handleUserData = async ({ value, field }) => {
    setNewUser({
      ...newUser,
      [field]: value,
    })
  }

  const statusChecked = async () => {
    handleUserData({
      value: newUser.status === 'inactive' ? 'active' : 'inactive',
      field: 'status',
    })
    setCheckBoxValue(!checkBoxValue)
  }

  const confirmPassword = async event => {
    setNewPassword(event.target.value)
    handleUserData({ value: oldPassword, field: 'password' })
  }

  const validateData = async () => {
    if (!newUser.name.trim('').length) {
      notify('Nombre de usuario inválido, favor de revisar', 'warning')
      return false
    }
    if (!__emailValidator.test(newUser.email)) {
      notify('Correo inválido, favor de revisar', 'warning')
      return false
    }
    if (!newPassword && !oldPassword) return true
    if (oldPassword !== newPassword) {
      notify('Las contraseñas no son iguales', 'warning')
      return false
    }
    if (oldPassword.length < 8) {
      notify('La contraseña debe tener un minimo de 8 caracteres', 'warning')
      return false
    }
    if (!/(\d)/.test(oldPassword)) {
      notify('La contraseña debe tener al menos un número', 'warning')
      return false
    }
    return true
  }

  return (
    <>
      <div className='whiteScreen'>
        {isLoading ? <Spinner /> : ''}
        <div className='body'>
          <div className='flex'>
            <button className='back-arrow' onClick={() => navigate('/admins')}>
              &lt;
            </button>
            <header>
              {newUser?.name ? newUser.name : 'Nuevo administrador'}
            </header>
          </div>
          <h6>
            Administradores &gt;{' '}
            {newUser?.name ? newUser?.name : 'Nuevo administrador'}
          </h6>
          <div className='main'>
            <div className='d-flex align-items-center'>
              <div className='col-2 m-2'>Nombre</div>
              <input
                className='input col-4 mb-2 mt-2'
                name='name'
                defaultValue={newUser?.name}
                onChange={event =>
                  handleUserData({ value: event.target.value, field: 'name' })
                }
              />
            </div>
            <div className='d-flex align-items-center'>
              <div className='col-2 m-2'>Correo</div>
              <input
                className='input col-4 mb-2 mt-2'
                defaultValue={newUser?.email}
                disabled={action !== 'create'}
                onChange={event =>
                  handleUserData({ value: event.target.value, field: 'email' })
                }
              />
            </div>
            <div className='d-flex align-items-center'>
              <div className='col-2 m-2'>Contraseña</div>
              <input
                type={showPassword[0] ? 'text' : 'password'}
                className='input col-4 mb-2 mt-2'
                defaultValue={newUser?.password}
                onChange={event => setOldPassword(event.target.value)}
              />
              <FontAwesomeIcon
                icon={showPassword[0] ? faEyeSlash : faEye}
                className={'eyeIcon1'}
                onClick={() =>
                  setShowPassword([!showPassword[0], showPassword[1]])
                }
              />
            </div>

            {oldPassword ? (
              <div className='d-flex align-items-center'>
                <div className='col-2 m-2'>Confirmar contraseña</div>
                <input
                  type={showPassword[1] ? 'text' : 'password'}
                  className='input col-4 mb-2 mt-2'
                  onBlur={event => confirmPassword(event)}
                />
                <FontAwesomeIcon
                  icon={showPassword[1] ? faEyeSlash : faEye}
                  className='eyeIcon1'
                  onClick={() =>
                    setShowPassword([showPassword[0], !showPassword[1]])
                  }
                />
              </div>
            ) : (
              ''
            )}

            <div className='d-flex align-items-center'>
              <div className='col-2 m-2'>Estatus</div>
              <div className='togleswitch'>
                <ToggleSwitch
                  label=' '
                  handleCheckBox={() => statusChecked()}
                  checkBoxValue={checkBoxValue}
                />
              </div>
            </div>
            <button className='btn btn-dark' name='saveUser' onClick={saveUser}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
