import axios from 'axios'
const URL = process.env.REACT_APP_SOCKET_URL

export default class Methods {
  static async getUsers(data) {
    return await axios.post(`${URL}/users/getUsers`, data)
  }

  static async getUser(email) {
    return await axios.post(`${URL}/users/getUser`, email)
  }

  static async addUser(data) {
    return await axios.post(`${URL}/users/addUser`, data)
  }

  static async updateUser({ user, isPasswordEdited }) {
    return await axios.put(`${URL}/users/updateUser`, {
      user,
      isPasswordEdited,
    })
  }

  static async deleteUser(data) {
    return await axios.post(`${URL}/users/deleteUser`, data)
  }

  static async signUp(data) {
    return await axios.post(`${URL}/users/signUp`, data)
  }

  static async getClientTypes() {
    return await axios.get(`${URL}/clientType/getClientTypes`)
  }

  static async quicksightGetEmbedURL(email) {
    return await axios.post(`${URL}/quicksight/getEmbedURL`, {
      email,
    })
  }

  static async generatePresignedURL(data) {
    return await axios.post(`${URL}/s3/generatePresignedURL`, data)
  }

  static async getDashboardData() {
    return await axios.get(`${URL}/dashboard/getDashboardData`)
  }

  static async getIndividualDashboardData(id) {
    return await axios.post(`${URL}/dashboard/getIndividualDashboardData`, id)
  }

  static async getDashboardUpdateData(data) {
    return await axios.post(`${URL}/dashboard/getDashbordUpdateData`, data)
  }

  static async updateDashboard(data) {
    return await axios.put(`${URL}/dashboard/updateDashboard`, data)
  }

  static async eraseDashboard(data) {
    return await axios.post(`${URL}/dashboard/eraseDashboard`, data)
  }

  static async getFilesReference(data) {
    return await axios.post(`${URL}/filesReference/getFilesReference`, data)
  }

  static async getFilesReferences(data) {
    return await axios.post(`${URL}/filesReference/getFilesReferences`, data)
  }

  static async postFilesReference(data, userId) {
    return await axios.post(`${URL}/filesReference/postFilesReference?userId=${userId}`, data)
  }

  static async putFilesReference(data, userId, isFileChanged, isImageChanged) {
    return await axios.put(`${URL}/filesReference/putFilesReference?userId=${userId}&isFileChanged=${isFileChanged}&isImageChanged=${isImageChanged}`, data)
  }

  static async deleteFilesReference(idFile) {
    return await axios.delete(`${URL}/filesReference/deleteFilesReference?id=${idFile}`)
  }

  static async putFileToS3(url, file, type) {
    const tmpAxiosInstance = axios.create()
    return await tmpAxiosInstance.put(url, file, {
      headers: {
        'Content-Type': type
      }
    })
  }
}
