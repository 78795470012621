import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faPen,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import './DownloadsFiles.css'
import { Link } from 'react-router-dom'
import Methods from '../../utils/Methods'
import { useEffect, useState } from 'react'
import Modal from '../../components/Modal'
import { useModal } from '../../hooks/useModal'
import { notify } from '../../utils/notify'
import { Pagination } from '../../components/Pagination'
import Spinner from '../../components/Spinner'

export default function FilesReferences({ state }) {
  const [currentPage, setCurrentPage] = useState(0)
  const [numberItems, setNumberItems] = useState(0)
  const [filesFiltered, setFilesFiltered] = useState(null)
  const [isOpenModal, openModal, closeModal, data] = useModal()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getFiles()
  }, [])

  const getFiles = async (offset = 0) => {
    setIsLoading(true)
    const response = await Methods.getFilesReferences({
      limit: 10,
      offset,
    })
    setCurrentPage(offset)
    setNumberItems(response.data.count)
    setFilesFiltered(response.data.rows)
    setIsLoading(false)
  }

  const deleteFile = async event => {
    setIsLoading(true)
    closeModal()
    await Methods.deleteFilesReference(event.id)
      .then(() => {
        notify('Archivo eliminado correctamente', 'success')
        getFiles()
      })
      .catch(error => {
        setIsLoading(false)
        console.log(error)
      })
  }

  return (
    <>
      <div className='whiteScreen'>
        {isLoading ? <Spinner /> : ''}
        <Modal
          isOpen={isOpenModal}
          title='Eliminar Archivo'
          label='¿Está seguro que desea eliminar el archivo?'
          onCancel={closeModal}
          cancelLabel='Cancelar'
          onAccept={e => deleteFile(data)}
          acceptLabel='Aceptar'
        />
        <div className='body'>
          <header>Actualización de Descargables</header>
          <div className='main'>
            <div className='flex'>
              <div className='button'>
                <Link to={'new'} state={{ action: 'create' }}>
                  <button className='primary-btn blue-btn'>
                    <FontAwesomeIcon icon={faPlus} className='plusIcon' />
                  </button>
                </Link>
              </div>
            </div>

            <br />
            <div className='header'>
              <div className='nameFile'>Nombre del archivo</div>
              <div className='updatedBy'>Actualizado por</div>
              <div className='updateDate'>Ultima carga</div>
              <div className='actionsEdit'>Editar</div>
              <div className='actionsDelete'>Eliminar</div>
            </div>
            {filesFiltered?.map(fileItem => (
              <div className='flex-row' key={fileItem.id}>
                <div className='nameFile'>{fileItem.nameFile}</div>
                <div className='updatedBy'>{fileItem.user.name}</div>
                <div className='updateDate'>{fileItem.updateDate}</div>
                <div className='actionsEdit'>
                  <Link to={`${fileItem.id}`} state={{ fileItem, action: 'edit' }}>
                    <FontAwesomeIcon icon={faPen} className='actionIcon' />
                  </Link>
                </div>
                <div className='actionsDelete'>
                  {(
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      className='actionIcon'
                      onClick={e =>
                        openModal({ id: fileItem.id, nameFile: fileItem.nameFile })
                      }
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className='pagination-div'>
            <Pagination
              numberItems={numberItems}
              currentPage={currentPage}
              pageSize={10}
              handleOffset={e => getFiles(currentPage + e)}
            />
          </div>
        </div>
      </div>
    </>
  )
}
