import Methods from '../../utils/Methods'
import { useContext, useEffect, useState, useRef } from 'react'
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { notify } from '../../utils/notify'
import { Pagination } from '../../components/Pagination'
import { UserContext } from '../../context/UserContext'
import Spinner from '../../components/Spinner'
import Modal from '../../components/Modal'
import { useModal } from '../../hooks/useModal'
import Papa from 'papaparse'

import './DashboardUpdate.css'

export default function DashboardUpdate() {
  const [dashboardList, setDashboardList] = useState()
  const [currentPage, setCurrentPage] = useState(0)
  const [numberItems, setNumberItems] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [currentDashboard, setCurrentDashboard] = useState({})
  const [isOpenModal, openModal, closeModal, data] = useModal()
  const [file, setFile] = useState(null)

  const context = useContext(UserContext)
  const inputRef = useRef(null)

  const handleIconClick = dashboard => {
    setCurrentDashboard(dashboard)
    inputRef.current.click()
  }

  const dashboardColumns = {
    1: 2,
    2: 2,
    3: 2,
    4: 16,
    5: 3,
    6: 2,
    7: 4,
    8: 2,
    9: 2,
    10: 2,
    11: 2,
    12: 4,
    13: 2,
    14: 2,
    15: 2,
    16: 4,
    17: 2,
    18: 2,
    19: 4,
    20: 2,
    21: 4,
    22: 2,
    23: 5,
    24: 4,
    25: 3,
    26: 5,
    27: 5,
    28: 2,
    29: 2,
    30: 2,
    31: 3,
    32: 2,
    33: 2,
  }

  const handleFileChange = event => {
    setFile(event.target.files[0])
    inputRef.current.value = null
    setIsLoading(true)
  }

  const createCSV = file => {
    Papa.parse(file, {
      header: true,
      complete: results => {
        const columNumber = Object.keys(results.data[0]).length
        if (columNumber === dashboardColumns[currentDashboard.id]) {
          openModal(results.data)
        } else {
          notify(
            'No coinciden el numero de columnas con el de la tabla. Favor de revisar el documento.',
            'error'
          )
        }
        setIsLoading(false)
      },
    })
  }

  useEffect(async () => {
    getDashboard()
  }, [])

  useEffect(async () => {
    if (file) {
      createCSV(file)
    }
  }, [file])

  const getDashboard = async (offset = 0) => {
    const response = await Methods.getDashboardUpdateData({
      limit: 10,
      offset,
    })
    setDashboardList(response.data.rows)
    setCurrentPage(offset)
    setNumberItems(response.data.count)
  }

  const downloadFile = async dashboard => {
    const { id, name } = dashboard
    const dashboardData = await (
      await Methods.getIndividualDashboardData({ id })
    ).data.data

    const header = Object.keys(dashboardData[0])
    const main = removeObjects(dashboardData)

    const csv = Papa.unparse([header, ...main])
    downloadCSV({ csv, name })
  }

  const removeObjects = data => {
    return data.map(item => {
      return Object.keys(item).map(key => {
        if (item[key] && typeof item[key] === 'object') {
          const itemKey = Object.values(item[key])
          return itemKey.length ? itemKey[0] : itemKey
        } else {
          return item[key]
        }
      })
    })
  }

  const downloadCSV = event => {
    const { csv, name } = event

    const blob = new Blob([csv], { type: 'application/csv' })
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.download = `${name}.csv`
    a.href = url

    document.body.appendChild(a)
    a.click()
    a.remove()
    URL.revokeObjectURL(url)
  }

  const updateTable = async data => {
    setIsLoading(true)
    const isCatalog = currentDashboard.isCatalog
    const dashboardId = currentDashboard.id
    for (let i = 0; i < data.length; i++) {
      if (data[i].per25 === '' || data[i]?.amount === '') {
        data.splice(i, i)
      }
    }
    console.log(data)
    if (!isCatalog) await Methods.eraseDashboard({ dashboardId })
    await Methods.updateDashboard({
      data,
      dashboardId,
      isCatalog,
      userId: context.user.id,
    })
      .then(() => {
        getDashboard(currentPage)
        setIsLoading(false)
        notify('Datos actualizados correctamente', 'success')
        closeModal()
      })
      .catch(error => {
        setIsLoading(false)
        closeModal()
        notify(
          'No se pudo actualizar alguno de los campos. Favor de revisar el documento.',
          'error'
        )
        console.log(error)
      })
  }

  const cancelFile = () => {
    setFile(null)
    closeModal()
  }

  return (
    <>
      <div className='whiteScreen'>
        {isLoading ? <Spinner /> : ''}
        <Modal
          isOpen={isOpenModal}
          title={currentDashboard.name}
          label='¿Confirma actualizar los datos?'
          onCancel={cancelFile}
          cancelLabel='Cancelar'
          onAccept={e => updateTable(data)}
          acceptLabel='Aceptar'
        />
        <div className='body'>
          <header>Actualización de Tableros</header>
          <div className='main'>
            <br />
            <div className='header'>
              <div className='name'>Tablero</div>
              <div className='updatedBy'>Actualizado por</div>
              <div className='updatedAt'>Última carga</div>
              <div className='actions'>Descarga</div>
              <div className='actions'>Carga</div>
            </div>
            {dashboardList?.map(dashboard => (
              <div className='flex-row' key={dashboard.id}>
                <div className='name'>{dashboard.name}</div>
                <div className='updatedBy'>
                  {dashboard.user?.name || 'Sistema'}
                </div>
                <div className='updatedAt'>
                  {new Date(dashboard.updatedAt).toLocaleString()}
                </div>
                <div className='actions'>
                  <FontAwesomeIcon
                    icon={faDownload}
                    className='actionIcon'
                    onClick={e => downloadFile(dashboard)}
                  />
                </div>
                <div className='actions'>
                  <FontAwesomeIcon
                    className='csvIcon'
                    icon={faUpload}
                    onClick={e => handleIconClick(dashboard)}
                  />
                  <input
                    type='file'
                    accept='.csv'
                    ref={inputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className='pagination-div'>
            <Pagination
              numberItems={numberItems}
              currentPage={currentPage}
              pageSize={10}
              handleOffset={e => getDashboard(currentPage + e)}
            />
          </div>
        </div>
      </div>
    </>
  )
}
