import Methods from '../../utils/Methods'
import './Downloads.css'
import { useEffect, useState } from 'react'
import Spinner from '../../components/Spinner'

export default function Downloads() {
  const [filesFiltered, setFilesFiltered] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [imagesUrls, setImagesUrls] = useState({})

  useEffect(() => {
    getFiles()
  }, [])

  const getFiles = async (offset = 0) => {
    setIsLoading(true)
    const response = await Methods.getFilesReferences({
      limit: 100,
      offset,
    })
    setFilesFiltered(response.data.rows)
    setIsLoading(false)

    const imagesUrls = {}
    for (const fileItem of response.data.rows) {
      const imageUrl = await download(fileItem.referenceImage, 'images')
      imagesUrls[fileItem.id] = imageUrl
    }
    setImagesUrls(imagesUrls)
  }

  const download = async (fileName, type) => {
    const presignedURL = await Methods.generatePresignedURL({
      path: `system/${type}`,
      fileName,
      type: 'getObject'
    })
    if (type === 'files') {
      window.open(presignedURL.data, '_blank')
    } else {
      const url = presignedURL.data
      const iend = url.indexOf('?')
      if (iend !== -1) {
        const subUrl = url.substring(0, iend)
        return subUrl
      }
    }
  }
  return (
    <div className='whiteScreen whiteScreen-downloads'>
      {isLoading ? <Spinner /> : ''}
      <div className='body'>
        <header>
          Descargables
        </header>
        <h6>Estudios y resumen ejecutivo</h6>
        <div className='main main-downloads'>
          <div className='flex'></div>
          {filesFiltered?.map(fileItem => (
            // eslint-disable-next-line react/jsx-key
            <img
              key={fileItem.id}
              src={imagesUrls[fileItem.id]}
              className='download-image'
              alt={fileItem.nameFile}
              onClick={() => download(fileItem.referenceFile, 'files')}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
