import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMagnifyingGlass,
  faPlus,
  faArrowsRotate,
  faPen,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import './Client.css'
import { Link } from 'react-router-dom'
import Methods from '../../utils/Methods'
import { useContext, useEffect, useState } from 'react'
import { classNames } from '../../utils/classNames'
import Modal from '../../components/Modal'
import { useModal } from '../../hooks/useModal'
import { notify } from '../../utils/notify'
import { Pagination } from '../../components/Pagination'
import { UserContext } from '../../context/UserContext'
import Spinner from '../../components/Spinner'

export default function Clients({ user, state }) {
  const [currentPage, setCurrentPage] = useState(0)
  const [numberItems, setNumberItems] = useState(0)
  const [usersFiltered, setUsersFiltered] = useState(null)
  const [searchString, setSearchString] = useState('')
  const [isOpenModal, openModal, closeModal, data] = useModal()
  const [userEmail, setUserEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const context = useContext(UserContext)

  useEffect(() => {
    setUserEmail(context.user.email)
    getClients('')
    context.getClientTypes()
  }, [])

  const getClients = async (serchString, offset = 0) => {
    setIsLoading(true)
    if (context.user.userType === 'admin') {
      const response = await Methods.getUsers({
        filter: {
          userType: 'client',
          search: serchString,
        },
        limit: 10,
        offset,
      })
      setCurrentPage(offset)
      setNumberItems(response.data.count)
      setUsersFiltered(response.data.rows)
      setIsLoading(false)
    } else {
      const client = await Methods.getUser({ email: context.user.email })
      setCurrentPage(0)
      setNumberItems(1)
      setUsersFiltered([client.data])
      setIsLoading(false)
    }
  }

  const searchUser = async searchString => {
    setSearchString(searchString)
    getClients(searchString)
  }

  const deleteClient = async event => {
    setIsLoading(true)
    await Methods.deleteUser(event)
      .then(() => {
        notify('Usuario eleminado correctamente', 'success')
        closeModal()
        getClients('')
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  const statusLabel = {
    active: 'Activo',
    inactive: 'Inactivo',
  }
  return (
    <>
      <div className='whiteScreen'>
        {isLoading ? <Spinner /> : ''}
        <Modal
          isOpen={isOpenModal}
          title='Eliminar Usuario'
          label='¿Está seguro que desea eliminar el usuario?'
          onCancel={closeModal}
          cancelLabel='Cancelar'
          onAccept={e => deleteClient(data)}
          acceptLabel='Aceptar'
        />
        <div className='body'>
          <header>Clientes</header>
          <h6>Control de Clientes</h6>
          <div className='main'>
            <div className='flex'>
              <div className='button'>
                <Link to={'new'} state={{ action: 'create' }}>
                  <button className='primary-btn blue-btn'>
                    <FontAwesomeIcon icon={faPlus} className='plusIcon' />
                  </button>
                </Link>
              </div>
              <div className='input-search'>
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className='searchIcon'
                />
                <input
                  type='search'
                  className='search'
                  placeholder='Buscar'
                  onChange={e => searchUser(e.target.value)}
                />
              </div>
              <div className='col-1'>
                <button
                  className='primary-btn blue-btn'
                  onClick={e => getClients(searchString)}
                >
                  <FontAwesomeIcon icon={faArrowsRotate} className='plusIcon' />
                </button>
              </div>
            </div>

            <br />
            <div className='header'>
              <div className='company'>Empresa</div>
              <div className='clientName'>Nombre</div>
              <div className='clientEmail'>Correo</div>
              <div className='clientStatus'>Estatus</div>
              <div className='clientType'>Tipo</div>
              <div className='clientType'>Fecha vencimiento</div>
              <div className='actions'>Acciones</div>
            </div>
            {usersFiltered?.map(user => (
              <div className='flex-row' key={user.id}>
                <div className='company'>{user.company}</div>
                <div className='clientName'>{user.name}</div>
                <div className='clientEmail'>{user.email}</div>
                <div className='clientStatus'>
                  <div className={classNames('status-label', user.status)}>
                    {statusLabel[user.status]}
                  </div>
                </div>
                <div className='clientType'>
                  {context.clientTypes
                    ? context.clientTypes[user.clientTypeId - 1]?.label
                    : ''}
                </div>
                <div className='accessEnd'>{user.accessEnd}</div>
                <div className='actions'>
                  <Link to={`${user.id}`} state={{ user, action: 'edit' }}>
                    <FontAwesomeIcon icon={faPen} className='actionIcon' />
                  </Link>
                  {userEmail === user.email ? (
                    ''
                  ) : (
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      className='actionIcon'
                      onClick={e =>
                        openModal({ id: user.id, email: user.email })
                      }
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className='pagination-div'>
            <Pagination
              numberItems={numberItems}
              currentPage={currentPage}
              pageSize={10}
              handleOffset={e => getClients(searchString, currentPage + e)}
            />
          </div>
        </div>
      </div>
    </>
  )
}
