import { useLocation, useNavigate } from 'react-router'
import './DownloadsFilesDetail.css'
import { useEffect, useState, useRef, useContext } from 'react'
import Methods from '../../../utils/Methods'
import { notify } from '../../../utils/notify'
import Spinner from '../../../components/Spinner'
import { UserContext } from '../../../context/UserContext'

export default function DownloadsFilesDetail() {
  const location = useLocation()
  const { fileItem, action } = location.state
  const [fileData, setFileData] = useState('')
  const [imageData, setImageData] = useState('')
  const [nameFileData, setNameFileData] = useState('')
  const [nameImageData, setNameImageData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [userId, setUserId] = useState('')
  const [newFile, setNewFile] = useState({
    id: '0',
    nameFile: '',
    nameFileData: '',
    nameImageData: ''
  })
  const [isFileChanged, setIsFileChanged] = useState(false)
  const [isImageChanged, setIsImageChanged] = useState(false)
  const inputFileRef = useRef(null)
  const inputImageRef = useRef(null)
  const context = useContext(UserContext)

  const navigate = useNavigate()

  useEffect(async() => {
    setUserId(context.user.id)

    if (action !== 'create') {
      setIsLoading(true)
      const result = await Methods.getFilesReference({ nameFile: fileItem.nameFile })
      setIsLoading(false)

      setNameFileData(result.data.referenceFile)
      setNameImageData(result.data.referenceImage)

      const editD = {
        id: fileItem.id,
        nameFile: fileItem.nameFile,
        nameFileData: result.data.referenceFile,
        nameImageData: result.data.referenceImag
      }

      setNewFile({ ...newFile, ...editD })
    }
  }, [])

  const handleFileChange = event => {
    setFileData(event.target.files[0])
    setNameFileData(event.target.files[0].name)
    handleFileData({ value: event.target.files[0].name, field: 'nameFileData' })
    inputFileRef.current.value = null
    setIsFileChanged(true)
  }
  const handleImageChange = event => {
    setImageData(event.target.files[0])
    setNameImageData(event.target.files[0].name)
    handleFileData({ value: event.target.files[0].name, field: 'nameImageData' })
    inputImageRef.current.value = null
    setIsImageChanged(true)
  }

  const handleFileIconClick = () => {
    inputFileRef.current.click()
  }
  const handleImageIconClick = () => {
    inputImageRef.current.click()
  }

  const saveFile = async () => {
    setIsLoading(true)
    if (Object.values(newFile).some(x => x === '')) {
      setIsLoading(false)
      notify('Favor de llenar todos los campos', 'warning')
      return
    }

    if (action === 'create') {
      if (!fileData) {
        setIsLoading(false)
        notify('Favor de llenar todos los campos', 'warning')
        return
      }
      if (!imageData) {
        setIsLoading(false)
        notify('Favor de llenar todos los campos', 'warning')
        return
      }
    }

    if (!(await validateData())) {
      setIsLoading(false)
      return
    }

    action === 'create' ? registerDownloadsFiles() : updateDownloadsFiles()
  }

  const putFileToS3 = async (file, type) => {
    const paramsFile = {
      path: `system/${type}`,
      fileName: file.name,
      type: 'putObject',
      contentType: file.type || 'application/octet-stream'
    }
    const presignedFileURL = await Methods.generatePresignedURL(paramsFile)

    const reader = new FileReader()

    reader.readAsArrayBuffer(file)
    reader.onload = async () => {
      const data = reader.result

      await Methods.putFileToS3(presignedFileURL.data, data, file.type || 'application/octet-stream')
    }
  }

  const registerDownloadsFiles = async () => {
    try {
      await Methods.postFilesReference({ ...newFile }, userId)

      await putFileToS3(fileData, 'files')
      await putFileToS3(imageData, 'images')

      notify('Archivo agregado correctamente', 'success')
      navigate('/downloadsFiles')
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      notify(error.response?.data?.message, 'error')
    }
    setIsLoading(false)
  }

  const updateDownloadsFiles = async () => {
    try {
      await Methods.putFilesReference({ ...newFile }, userId, isFileChanged, isImageChanged)

      if (isFileChanged) {
        await putFileToS3(fileData, 'files')
      }
      if (isImageChanged) {
        await putFileToS3(imageData, 'images')
      }

      notify('Archivo actualizado correctamente', 'success')
      navigate('/downloadsFiles')
    } catch (error) {
      console.log(error)
      notify(error.response.data.message, 'error')
    }
    setIsLoading(false)
  }

  const handleFileData = async ({ value, field }) => {
    setNewFile({
      ...newFile,
      [field]: value,
    })
  }

  const validateData = async () => {
    if (!newFile.nameFile.trim('').length) {
      notify('Nombre de archivo inválido, favor de revisar', 'warning')
      return false
    }
    return true
  }

  return (
    <>
      <div className='whiteScreen'>
        {isLoading ? <Spinner /> : ''}
        <div className='body'>
          <div className='flex'>
            <button className='back-arrow' onClick={() => navigate('/downloadsFiles')}>
              &lt;
            </button>
            <header>
              {newFile?.nameFile ? newFile.nameFile : 'Cargar nuevo archivo'}
            </header>
          </div>
          <h6>
            Descargables &gt;{' '}
            {newFile?.nameFile ? newFile?.nameFile : 'Cargar nuevo archivo'}
          </h6>
          <div className='main'>
            <div className='d-flex align-items-center'>
              <div className='col-3 m-2'>Nombre</div>
              <input
                className='input col-8 mb-3 mt-2'
                name='nameFile'
                defaultValue={newFile?.nameFile}
                onChange={event =>
                  handleFileData({ value: event.target.value, field: 'nameFile' })
                }
              />
            </div>
            <div className='d-flex align-items-center'>
              <div className='col-3 m-2'>Adjuntar nuevo archivo</div>
                <button
                    className='input col-3'
                    style={{ backgroundColor: 'skyblue' }}
                    onClick={e => handleFileIconClick()}
                    >
                        Seleccione Archivo
                </button>
                <input
                    type='file'
                    accept='.pdf'
                    style={{ display: 'none' }}
                    ref={inputFileRef}
                    onChange={handleFileChange}
                    />
                <input
                    className='input col-5 mb-2 mt-2'
                    defaultValue={nameFileData}
                    disabled
                />
            </div>
            <div className='d-flex align-items-center'>
              <div className='col-3 m-2'>Adjuntar imagen de portada</div>
                <button
                    className='input col-3'
                    style={{ backgroundColor: 'skyblue' }}
                    onClick={e => handleImageIconClick()}
                    >
                        Seleccione Archivo
                </button>
                <input
                    type='file'
                    accept='image/png, image/jpeg'
                    style={{ display: 'none' }}
                    ref={inputImageRef}
                    onChange={handleImageChange}
                    />
                <input
                    className='input col-5 mb-2 mt-2'
                    defaultValue={nameImageData}
                    disabled
                />
            </div>

            <button className='btn btn-dark mt-5' name='saveFile' onClick={saveFile}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
