import './ToggleSwitch.css'

export const ToggleSwitch = ({
  label,
  handleCheckBox,
  checkBoxValue,
  isDisabled,
}) => {
  return (
    <div className='container'>
      {label}{' '}
      <div className='toggle-switch'>
        <input
          type='checkbox'
          className='checkbox'
          disabled={isDisabled}
          name={label}
          id={label}
          defaultChecked={checkBoxValue}
          onClick={handleCheckBox}
        />
        <label className='label-switch' htmlFor={label}>
          <span className={`inner ${isDisabled && 'disabled'}`} />
          <span className='switch' />
        </label>
      </div>
    </div>
  )
}

export default ToggleSwitch
