import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import LogoCaintra from '../../assets/CaintraLogo.png'
import LogoSueldos from '../../assets/logo.png'
import { Auth } from 'aws-amplify'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Login.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import {} from '@fortawesome/free-brands-svg-icons'
import { notify } from '../../utils/notify'
import { useNavigate } from 'react-router-dom'
import Methods from '../../utils/Methods'
import Spinner from '../../components/Spinner'

export default function Login() {
  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const context = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    context.setToken(undefined)
  }, [])

  const login = async () => {
    if (!email || !password) {
      notify('Favor de llenar los campos de correo y/o contraseña', 'warning')
      return
    }
    setIsLoading(true)
    try {
      await Auth.signIn(email, password)
      const currentUser = await Auth.currentSession()
      const token = currentUser.getIdToken().getJwtToken()
      localStorage.setItem('token', token)

      const user = await Methods.getUser({ email })
      console.log(user)
      if (user.data.status !== 'active') {
        notify(
          'Credenciales inválidas, favor de contactar con el administrador',
          'warning'
        )
        localStorage.removeItem('token')
        return
      }
      if (user.data.vencimiento === 'vencido') {
        notify(
          'Licencia vencida, favor de contactar a tu ejecutivo de atención',
          'warning'
        )
        localStorage.removeItem('token')
        return
      }
      const role = user.data.userType
      context.setUserData(user.data)
      await context.setToken(token)
      await context.timeoutSession(60)
      setIsLoading(false)
      if (role === 'admin') navigate('/admins')
      else navigate('/dashboard/true')
    } catch (error) {
      setIsLoading(false)
      console.log(error.message)
      const errorMessage = {
        NotAuthorizedException:
          'Credenciales incorrectas. Favor de revisar sus datos',
        UserNotFoundException:
          'Credenciales incorrectas. Favor de revisar sus datos',
        UserNotConfirmedException: 'Favor de validar tu correo electrónico',
      }
      if (error.message === 'Request failed with status code 400') {
        notify('Licencia vencida, favor de contactar a tu ejecutivo de atención', 'info')
      }
      notify(errorMessage[error.code], 'error')
      localStorage.removeItem('token')
    }
  }

  return (
    <div className='login'>
      <div className='blurer'>
        {isLoading ? <Spinner /> : ''}
        <div className='internsLogo'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-3'>
                <img src={LogoCaintra} className='logoCaintra' alt='' />
              </div>
              <div className='vl' />
              <img src={LogoSueldos} className='logoCaintra' alt='Sueldos y salarios'></img>
            </div>

            <div className='row'>
              <div className='startSession'>
                <span>Inicia sesión</span>
              </div>
              <div className='enterCredentials'>
                <span>Ingresa tus credenciales</span>
              </div>
            </div>

            <div className='row'>
              <div className='emailPassword'>Correo</div>
              <div className='input-field'>
                <input
                  type='email'
                  className='input'
                  name='email'
                  id='email'
                  required
                  placeholder='Ingresa tu correo'
                  onChange={event => setEmail(event.target.value)}
                />
              </div>
            </div>

            <div className='row'>
              <div className='emailPassword'>Contraseña</div>
              <div className='input-field'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className='input'
                  name='password'
                  id='password'
                  required
                  placeholder='Ingresa tu contraseña'
                  onChange={event => setPassword(event.target.value)}
                  onKeyDown={event => {
                    if (event.key === 'Enter') login()
                  }}
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className='eye-icon'
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            </div>

            <div className='row'>
              <div className='input-field'>
                <button
                  className='btn btn-dark input'
                  name='startSession'
                  placeholder='Iniciar sesión'
                  id=''
                  onClick={login}
                >
                  Iniciar sesión
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
