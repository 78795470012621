import './Pagination.css'
export const Pagination = ({
  numberItems,
  currentPage,
  pageSize,
  handleOffset,
}) => {
  return (
    <>
      <div className='pagination'>
        <div>
          <button
            className={
              currentPage >= 1 ? 'primary-btn blue-btn' : 'primary-btn grey-btn'
            }
            onClick={e => handleOffset(-pageSize)}
            disabled={currentPage <= 1}
          >
            &lt;
          </button>
        </div>
        <div>
          <button
            className={
              currentPage >= numberItems - pageSize
                ? 'primary-btn grey-btn'
                : 'primary-btn blue-btn'
            }
            placeholder='>'
            onClick={e => handleOffset(pageSize)}
            disabled={currentPage >= numberItems - pageSize}
          >
            &gt;
          </button>
        </div>
      </div>
    </>
  )
}
