import LogoCaintra from '../assets/CaintraLogo.png'
import LogoSueldos from '../assets/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRightFromBracket,
  faBackward,
  faForward,
} from '@fortawesome/free-solid-svg-icons'
import './SideBar.css'
import { NavLink } from 'react-router-dom'
import { useContext, useState } from 'react'
import { UserContext } from '../context/UserContext'

export function SideBar({ items, handleSideBarExpanded }) {
  const [isOpen, setIsOpen] = useState(false)

  const context = useContext(UserContext)

  const handleCloseSession = () => {
    context.logout()
  }

  const handleClick = () => {
    setIsOpen(!isOpen)
    handleSideBarExpanded(isOpen)
  }

  return (
    <div className={isOpen ? 'sideBar expanded' : 'sideBar collapsed'}>
      <div className='card-tittle'>
        <img src={LogoCaintra} className='miniLogoCaintra' alt='' />
        <div className={isOpen ? 'profileInfo' : 'profileInfo collapsed'}>
          <div className='vertical-line'></div>
          <img src={LogoSueldos} className='logoSide' alt='Sueldos y salarios'></img>
        </div>
      </div>

      {items.map(item => (
        <div className='sideBarMenuItem' key={item.id}>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'navLink navActive' : 'navLink'
            }
            to={item.url}
            state={{ user: context.user, action: 'edit' }}
          >
            <div className={isOpen ? 'itemContent' : 'itemContent collapsed'}>
              <FontAwesomeIcon icon={item.icon} className='icon' />
              <div className='label'>{item.label}</div>
            </div>
          </NavLink>
          {!isOpen ? <div className='tooltips'>{item.label} </div> : ''}
        </div>
      ))}

      <div className='footer'>
        <div className='menuButton'>
          <button className='expandButton' onClick={handleClick}>
            <FontAwesomeIcon
              icon={isOpen ? faBackward : faForward}
              className={isOpen ? 'icon-expanded' : 'icon'}
            />
          </button>
        </div>

        <div className='closeSession'>
          <a onClick={handleCloseSession}>
            <div className={isOpen ? 'itemContent' : 'itemContent collapsed'}>
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                className='icon'
              />
              <div className={isOpen ? 'label' : 'closeSession-collapsed'}>
                Cerrar sesión
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}
