const env = 'prod'

export function getPoolData() {
  const UserPoolId = {
    dev: 'us-east-1_gCIGWZUPE',
    testing: 'us-east-1_9cZsC3J0I',
    uat: 'us-east-1_xDsXhXcIp',
    prod: 'us-east-1_dllX7CyuN',
  }
  const ClientId = {
    dev: '1rt6kh66hndeu17l3e475onnd2',
    testing: '264jjgr3jisqi4q98ov9i5afsr',
    uat: '7tgjnmdlds5na6cljvfk67u2sc',
    prod: '1b3o7u01pio6k252phr4lugu90',
  }

  return {
    UserPoolId: UserPoolId[env],
    ClientId: ClientId[env],
    Region: 'us-east-1',
  }
}
