import Methods from '../../utils/Methods'
import { useContext, useEffect, useState, useRef } from 'react'
import { UserContext } from '../../context/UserContext'
import Spinner from '../../components/Spinner'
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import Modal from '../../components/Modal'
import { useModal } from '../../hooks/useModal'
import welcomeImage from '../../assets/welcomeImage.png'
import './Dashboard.css'
import { useParams } from 'react-router-dom'

export default function Dashboard() {
  const dashboardParams = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const context = useContext(UserContext)
  const ref = useRef(null)
  const [isOpenModal, openModal, closeModal] = useModal()

  useEffect(() => {
    if (dashboardParams.firstTime) {
      openModal()
    }
    quicksightGetEmbedURL()
  }, [])

  function onError(payload) {
    console.log(payload)
  }

  const quicksightGetEmbedURL = async () => {
    await context.timeoutSession(30)
    const response = await Methods.quicksightGetEmbedURL(context.user.email)
    const containerDiv = ref.current
    const options = {
      url: response.data.url,
      container: containerDiv,
      parameters: {
        country: 'United States',
      },
      locale: 'en-US',
      sheetTabsDisabled: false,
      footerPaddingEnabled: true,
      printEnabled: true,
    }
    const embeddedDashboardExperience =
      QuickSightEmbedding.embedDashboard(options)
    embeddedDashboardExperience.on('error', onError)
    setTimeout(() => {
      setIsLoading(false)
    }, 3500)
  }

  const getDashboardData = async () => {
    return await Methods.getDashboardData()
  }

  const downloadExcel = async () => {
    const dashboardData = await getDashboardData()

    const workbook = new ExcelJS.Workbook()
    workbook.creator = 'CESS'
    workbook.created = new Date()
    workbook.modified = new Date()

    buildGeneralIndicator({
      workbook,
      data: dashboardData.data.generalIndicator,
    })
    buildRotationType({ workbook, data: dashboardData.data.rotationType })
    buildRotationNL({ workbook, data: dashboardData.data.rotationNL })
    buildGraduatesPerArea({
      workbook,
      data: dashboardData.data.graduatesPerArea,
    })
    buildPercentil({ workbook, data: dashboardData.data.percentil })
    buildInflation({ workbook, data: dashboardData.data.inflation })
    buildPEA({ workbook, data: dashboardData.data.PEA })
    buildBenefitsPerPosition({
      workbook,
      data: dashboardData.data.benefitsPerPosition,
    })
    buildPosition({ workbook, data: dashboardData.data.position })
    buildCitySegmentation({
      workbook,
      data: dashboardData.data.citySegmentation,
    })
    buildIndustrySegmentation({
      workbook,
      data: dashboardData.data.industrySegmentation,
    })
    buildSizeSegmentation({
      workbook,
      data: dashboardData.data.sizeSegmentation,
    })
    buildFrecuencyField({
      workbook,
      data: dashboardData.data.frecuencyField,
    })
    buildTurnover({ workbook, data: dashboardData.data.turnover })
    buildCapitalSource({ workbook, data: dashboardData.data.capitalSource })
    buildSalaryIncreasePerMonth({
      workbook,
      data: dashboardData.data.salaryIncreasePerMonth,
    })
    buildSalaryIncreasePerYear({
      workbook,
      data: dashboardData.data.salaryIncreasePerYear,
    })
    buildSyndicateConfederation({
      workbook,
      data: dashboardData.data.syndicateConfederation,
    })
    buildAbsenteeismRate({ workbook, data: dashboardData.data.absenteeismRate })
    buildCompanyWithPractitioner({
      workbook,
      data: dashboardData.data.companyWithPractitioner,
    })
    buildPractitioner({ workbook, data: dashboardData.data.practitioner })
    buildTopPractitionerArea({
      workbook,
      data: dashboardData.data.topPractitionerArea,
    })
    buildCareerPlan({ workbook, data: dashboardData.data.careerPlan })
    buildScholarshipSupportPercentage({
      workbook,
      data: dashboardData.data.scholarshipSupportPercentage,
    })
    buildStudySupport({ workbook, data: dashboardData.data.studySupport })
    buildProportionByGender({
      workbook,
      data: dashboardData.data.proportionByGender,
    })
    buildGenerationalPercentage({
      workbook,
      data: dashboardData.data.generationalPercentage,
    })
    buildEmployeesByField({
      workbook,
      data: dashboardData.data.employeesByField,
    })

    workbook.xlsx.writeBuffer().then(function (buffer) {
      const blob = new Blob([buffer], { type: 'applicationi/xlsx' })
      saveAs(blob, 'Estudio de sueldos y beneficios.xlsx')
    })
  }

  const buildRotationType = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Tipo de rotación')
    const headers = ['Tamaño', 'Sindicalizada', 'Administrativa']
    sheet.getRow(1).values = headers

    const rotationTypeColumns = [
      { key: 'size', width: 15 },
      { key: 'syndicate', width: 13 },
      { key: 'admin', width: 13 },
    ]

    sheet.columns = rotationTypeColumns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
        size: item.rotationSize.rotationSize,
      })
    })
    sheet.addRows(sheetData)

    sheet.getColumn('B').numFmt = '0.00%'
    sheet.getColumn('C').numFmt = '0.00%'
  }

  const buildRotationNL = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Rotación en NL')
    const headers = ['Ciudad', 'Sindicalizada', 'Administrativa']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'city', width: 20 },
      { key: 'syndicate', width: 13 },
      { key: 'admin', width: 13 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
        city: item.city.city,
      })
    })
    sheet.addRows(sheetData)

    sheet.getColumn('B').numFmt = '0.00%'
    sheet.getColumn('C').numFmt = '0.00%'
  }

  const buildGeneralIndicator = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Indicadores generales')
    const headers = ['Indicador', 'Valor']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'name', width: 40 },
      { key: 'value', width: 10 },
    ]

    sheet.columns = columns
    sheet.addRows(data)
  }

  const buildGraduatesPerArea = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Graduados por área')
    const headers = ['Licenciatura/ Ingeniería', 'Porcentaje', 'Graduados']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'degree', width: 55 },
      { key: 'percentage', width: 10 },
      { key: 'graduates', width: 13 },
    ]

    sheet.columns = columns
    sheet.addRows(data)

    sheet.getColumn('B').numFmt = '0.00%'
    sheet.getColumn('C').numFmt = '#,###'
  }

  const buildPercentil = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Percentil')
    const headers = ['Área', 'Tipo de personal', 'Puesto', '25%', '50%', '75%']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'area', width: 25 },
      { key: 'personalType', width: 22 },
      { key: 'position', width: 37 },
      { key: 'per25', width: 10 },
      { key: 'per50', width: 10 },
      { key: 'per75', width: 10 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
        area: item.position.area.area,
        personalType: item.position.personalType.personalType,
        position: item.position.position,
      })
    })
    sheet.addRows(sheetData)

    sheet.getColumn('D').numFmt = '#,###'
    sheet.getColumn('E').numFmt = '#,###'
    sheet.getColumn('F').numFmt = '#,###'
  }

  const buildInflation = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('INPC')
    const headers = ['Año', 'INPC']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'year', width: 8 },
      { key: 'inflation', width: 8 },
    ]

    sheet.columns = columns
    sheet.addRows(data)

    sheet.getColumn('B').numFmt = '0.00%'
  }

  const buildPEA = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('PEA')
    const headers = ['Ciudad', 'Población', 'PEA', 'PEA Manufactura']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'city', width: 20 },
      { key: 'poblation', width: 9 },
      { key: 'PEA', width: 9 },
      { key: 'manufacturingPEA', width: 15 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
        city: item.city.city,
      })
    })
    sheet.addRows(sheetData)

    sheet.getColumn('B').numFmt = '#,###'
    sheet.getColumn('C').numFmt = '#,###'
    sheet.getColumn('D').numFmt = '0.00%'
  }

  const buildBenefitsPerPosition = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Beneficios por puesto')
    const headers = [
      'Tipo de personal',
      'Aguinaldo',
      'Prima Vacacional',
      'Días de vacaciones',
      'Fondo de ahorro',
      'Vales de despensa',
      'Bono de puntualidad',
      'Bono de asistencia',
      'Bono de productividad',
      'Bono escolar',
      'Seguro de gastos médicos mayores',
      'Seguro de vida',
      'Seguro de gastos médicos menores',
      'Seguro de automovil',
      'Otro',
    ]
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'personalType', width: 23 },
      { key: 'christmasBonus', width: 9 },
      { key: 'vacationalBonus', width: 14 },
      { key: 'vacationDays', width: 15 },
      { key: 'savingFund', width: 14 },
      { key: 'pantryVoucher', width: 15 },
      { key: 'punctualityBonus', width: 17 },
      { key: 'attendanceBonus', width: 15 },
      { key: 'productivityBonus', width: 18 },
      { key: 'schoolVoucher', width: 11 },
      { key: 'majorMedicalInsurance', width: 27 },
      { key: 'lifeInsurance', width: 12 },
      { key: 'menorMedicalInsurance', width: 12 },
      { key: 'carInsurance', width: 12 },
      { key: 'other', width: 12 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
        personalType: item.personalType.personalType,
        majorMedicalInsurance: item.majorMedicalInsurance ? 'Si' : '',
        lifeInsurance: item.lifeInsurance ? 'Si' : '',
        menorMedicalInsurance: item.menorMedicalInsurance ? 'Si' : '',
        carInsurance: item.carInsurance ? 'Si' : '',
        other: item.other ? 'Si' : '',
      })
    })
    sheet.addRows(sheetData)
    sheet.getColumn('C').numFmt = '0.00%'
    sheet.getColumn('E').numFmt = '0.00%'
    sheet.getColumn('F').numFmt = '0.00%'
    sheet.getColumn('G').numFmt = '0.00%'
    sheet.getColumn('H').numFmt = '0.00%'
    sheet.getColumn('I').numFmt = '0.00%'
    sheet.getColumn('J').numFmt = '#,###'
  }

  const buildPosition = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Descripción de puestos')
    const headers = [
      'Tipo de personal',
      'Puesto',
      'Años de experiencia',
      'Descripción',
    ]
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'personalType', width: 22 },
      { key: 'position', width: 37 },
      { key: 'experienceYears', width: 16 },
      { key: 'description', width: 150 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
        personalType: item.personalType.personalType,
      })
    })
    sheet.addRows(sheetData)
  }

  const buildCitySegmentation = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Segmentación por ciudad')
    const headers = ['Ciudad', 'Puesto', 'Promedio']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'city', width: 15 },
      { key: 'position', width: 37 },
      { key: 'average', width: 9 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        average: item.average,
        city: item.city.city,
        position: item.position.position,
      })
    })
    sheet.addRows(sheetData)
    sheet.getColumn('C').numFmt = '#,###'
  }

  const buildIndustrySegmentation = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Segmentación por industria')
    const headers = ['Área', 'Puesto', 'Giro', '25%', '50%', '75%']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'area', width: 25 },
      { key: 'position', width: 37 },
      { key: 'field', width: 18 },
      { key: 'per25', width: 12 },
      { key: 'per50', width: 12 },
      { key: 'per75', width: 12 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
        position: item.position.position,
        area: item.position.area.area,
        field: item.field.field,
      })
    })
    sheet.addRows(sheetData)

    sheet.getColumn('D').numFmt = '#,###'
    sheet.getColumn('E').numFmt = '#,###'
    sheet.getColumn('F').numFmt = '#,###'
  }

  const buildSizeSegmentation = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Segmentación por tamaño')
    const headers = ['Área', 'Puesto', 'Tamaño', '25%', '50%', '75%']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'area', width: 25 },
      { key: 'position', width: 37 },
      { key: 'rotationSize', width: 13 },
      { key: 'per25', width: 12 },
      { key: 'per50', width: 12 },
      { key: 'per75', width: 12 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
        position: item.position.position,
        area: item.position.area.area,
        rotationSize: item.rotationSize.rotationSize,
      })
    })
    sheet.addRows(sheetData)

    sheet.getColumn('D').numFmt = '#,###'
    sheet.getColumn('E').numFmt = '#,###'
    sheet.getColumn('F').numFmt = '#,###'
  }

  const buildFrecuencyField = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Giros encuestados')
    const headers = ['Giro', 'Cantidad']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'field', width: 27 },
      { key: 'amount', width: 8 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
        field: item.field.field,
      })
    })
    sheet.addRows(sheetData)
  }

  const buildTurnover = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Volumen de ventas')
    const headers = ['Volumen', 'Cantidad']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'sales', width: 25 },
      { key: 'amount', width: 8 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
      })
    })
    sheet.addRows(sheetData)
  }

  const buildCapitalSource = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Origen de capital')
    const headers = ['Origen', 'Cantidad']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'origin', width: 12 },
      { key: 'amount', width: 8 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
      })
    })
    sheet.addRows(sheetData)
  }

  const buildSalaryIncreasePerMonth = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Incremento de salario por mes')
    const headers = ['Tipo de Personal', 'Mes', 'Cantidad']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'personalType', width: 19 },
      { key: 'month', width: 10 },
      { key: 'frecuency', width: 8 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
        personalType: item.personalType.personalType,
      })
    })
    sheet.addRows(sheetData)

    sheet.getColumn('B').numFmt = '0.00%'
  }

  const buildSalaryIncreasePerYear = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Incremento de salario por año')
    const headers = ['Año', 'Salario']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'year', width: 5 },
      { key: 'salaryIncrease', width: 9 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
      })
    })
    sheet.addRows(sheetData)
    sheet.getColumn('B').numFmt = '0.00%'
  }

  const buildSyndicateConfederation = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Confederaciones de sindicato')
    const headers = ['Confederación', 'Cantidad']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'confederation', width: 25 },
      { key: 'amount', width: 8 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
      })
    })
    sheet.addRows(sheetData)
  }

  const buildAbsenteeismRate = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Tasa de ausentismo')
    const headers = ['Confederación', 'Cantidad']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'personalType', width: 19 },
      { key: 'absenteeismRate', width: 8 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
        personalType: item.personalType.personalType,
      })
    })
    sheet.addRows(sheetData)

    sheet.getColumn('B').numFmt = '0.00%'
  }

  const buildCompanyWithPractitioner = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Empresas con practicantes')
    const headers = ['Practicantes', 'Cantidad']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'hasPractitioner', width: 17 },
      { key: 'amount', width: 8 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
      })
    })
    sheet.addRows(sheetData)
  }

  const buildPractitioner = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Promedio de practicantes por Empresa')
    const headers = [
      'Cantidad de practicantes',
      'Horas laborales por semana',
      'Porcentaje de oportunidad',
      'Frecuencia de crecimiento',
    ]
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'practitionerAmount', width: 20 },
      { key: 'weeklyWorkingTime', width: 22 },
      { key: 'oportunityPercentage', width: 21 },
      { key: 'growingFrequency', width: 21 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
      })
    })
    sheet.addRows(sheetData)

    sheet.getColumn('C').numFmt = '0.00%'
    sheet.getColumn('D').numFmt = '0.00%'
  }

  const buildTopPractitionerArea = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Top de areas de practicantes')
    const headers = ['Área', 'Frecuencia']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'practitionerArea', width: 25 },
      { key: 'practitionerAmount', width: 10 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
      })
    })
    sheet.addRows(sheetData)
  }

  const buildCareerPlan = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Planes de carrera')
    const headers = ['Planes de Carrera', 'Cantidad']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'hasCareerPlan', width: 14 },
      { key: 'growingFrequency', width: 8 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
      })
    })
    sheet.addRows(sheetData)
  }

  const buildScholarshipSupportPercentage = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Apoyo de beca')
    const headers = ['Apoyo', 'Cantidad']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'scholarshipSupport', width: 10 },
      { key: 'supportAmount', width: 8 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
      })
    })
    sheet.addRows(sheetData)
  }

  const buildStudySupport = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Apoyo para el estudio')
    const headers = ['Apoyo', 'Cantidad']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'supportType', width: 11 },
      { key: 'supportAmount', width: 8 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
      })
    })
    sheet.addRows(sheetData)
  }
  const buildProportionByGender = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Proporción por género')
    const headers = ['Proporción', 'Cantidad']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'proportion', width: 17 },
      { key: 'amount', width: 8 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
      })
    })
    sheet.addRows(sheetData)
  }

  const buildGenerationalPercentage = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Porcentaje generacional')
    const headers = ['Generación', 'Promedio']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'generation', width: 25 },
      { key: 'amount', width: 9 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
      })
    })
    sheet.addRows(sheetData)
  }

  const buildEmployeesByField = params => {
    const { workbook, data } = params
    const sheet = workbook.addWorksheet('Volumen de empleados')
    const headers = ['Giro', 'Trabajadores estimados']
    sheet.getRow(1).values = headers

    const columns = [
      { key: 'field', width: 26 },
      { key: 'estimatedEmployees', width: 19 },
    ]

    sheet.columns = columns

    const sheetData = []
    data.forEach(item => {
      sheetData.push({
        ...item,
        field: item.field.field,
      })
    })
    sheet.addRows(sheetData)
  }

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        title='¡Bienvenido al portal!'
        label='Estamos encantados de tenerte aquí. Estamos seguros de que encontrarás todo lo que necesitan en nuestro sitio y esperamos que disfrutes de tu visita.
        Gracias por confiar en nosotros y esperamos verles de nuevo en el futuro.'
        onAccept={closeModal}
        acceptLabel='Entendido'
        image={welcomeImage}
      />
      {isLoading ? <Spinner /> : ''}
      <FontAwesomeIcon
        icon={faFileExcel}
        className={isLoading ? 'hidden' : 'download-excel'}
        onClick={() => downloadExcel()}
      />
      <label className='label-excel'>Descarga de excel</label>
      <div
        id='embedingContainer'
        ref={ref}
        className='dashboard-container'
      ></div>
    </>
  )
}
