import { useState, useEffect, createContext } from 'react'
import { Auth } from 'aws-amplify'
import Methods from '../utils/Methods'

export const UserContext = createContext()
export const UserProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || undefined)
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user')) || undefined
  )
  const [clientTypes, setClientTypes] = useState(
    JSON.parse(localStorage.getItem('clientTypes')) || undefined
  )
  const [timeoutIds, setTimeoutIds] = useState(
    Number(localStorage.getItem('timeoutId')) || 0
  )

  useEffect(() => {
    token ? onLoad() : logout()
    getClientTypes()
  }, [])

  const getClientTypes = async () => {
    const clientTypesData = await Methods.getClientTypes()
    const _clientTypeData = clientTypesData.data.map(x => ({
      value: x.id,
      label: x.type,
      info: x.packageInformation,
    }))
    setClientTypes(_clientTypeData)
    localStorage.setItem('clientTypes', JSON.stringify(_clientTypeData))
    return _clientTypeData
  }

  const onLoad = async tokenData => {
    if (tokenData) {
      localStorage.setItem('token', tokenData)
      setToken(tokenData)
    }
  }

  const logout = async () => {
    await Auth.signOut()
    if (timeoutIds) {
      timeoutIds.forEach(id => {
        clearTimeout(id)
      })
    }
    localStorage.removeItem('timeoutId')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('clientTypes')
    setTimeoutIds(undefined)
    setToken(undefined)
  }

  const setUserData = async userData => {
    setUser(userData)
    localStorage.setItem('user', JSON.stringify(userData))
  }

  const timeoutSession = async minutes => {
    const newTimeoutId = setTimeout(() => {
      logout()
    }, minutes * 60 * 1000)

    const timeoutArray = []
    if (timeoutIds) {
      timeoutArray.push(timeoutIds)
    }
    timeoutArray.push(newTimeoutId)

    setTimeoutIds(timeoutArray)
    localStorage.setItem('timeoutId', timeoutArray)
  }

  return (
    <UserContext.Provider
      value={{
        token,
        user,
        clientTypes,
        setToken,
        getClientTypes,
        setUserData,
        logout,
        onLoad,
        timeoutSession,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
